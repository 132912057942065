<template>
  <div v-if="table_permissions" class="custom-content-height">
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      :height="getvh(56)"
      show-select
      :single-select="disableSelectAllCheckbox"
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      item-key="id.id"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      mobile-breakpoint="40"
      @dblclick:row="showDetail"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <!--    no-data end-->
      <!--    table top-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-5"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>

          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <!--begin::Button-->
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('export')"
            >
              <button
                @click="$refs.exportSidebar.$data.exportSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                Export
              </button>
            </div>
            <!--end::Button-->

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="$refs.filterSidebar.$data.filterSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('upload')"
            >
              <button
                class="btn btn--print-upload"
                @click.prevent="showUploadModal"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-cloud-upload</v-icon>
                </span>
                Import
              </button>
              <!--              <Upload-->
              <!--                ref="upload"-->
              <!--                :updateTableContent="updateTableContent"-->
              <!--              ></Upload>-->
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
            >
              <button @click="showHoldOnModal" class="btn btn-warning">
                <span class="svg-icon">
                  <v-icon size="18">mdi-package-variant-closed</v-icon>
                </span>
                On Hold
              </button>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
            >
              <button @click="showCancelModal" class="btn btn-danger">
                <span class="svg-icon">
                  <v-icon dark size="18">mdi-alert-octagon-outline</v-icon>
                </span>
                Cancel Order
              </button>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
            >
              <button @click="revertAudit" class="btn btn-info">
                <span class="svg-icon">
                  <v-icon dark size="18">mdi-alert</v-icon>
                </span>
                Revert Audit / Un hold
              </button>
            </div>

            <!-- <Print
              :items="selected"
              :types="types"
              :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
            ></Print> -->
          </div>
        </div>
        <!-- Export Sidebar start -->
        <ExportSidebar
          v-if="exportHeaders.length > 0"
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          v-if="filters.length > 0"
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->

        <!--        <hr />-->
        <Others ref="otherData" :showNextOrder="showNextOrder"></Others>
      </template>
      <!-- column -->
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>

      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.id="{ item, value }">
        <span class="first-text" :id="value.id">
          <v-tooltip bottom v-if="item.id.detail.merged_order">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span>{{ value.id }}</span>
                <v-icon>mdi-file</v-icon>
              </div>
            </template>
            <span>Merged with Order:{{ item.id.detail.merged_order }}</span>
          </v-tooltip>
          <div v-else>{{ value.id }}</div>
        </span>
      </template>

      <template #item.systemId="{ item, value }">
        <span class="second-text" :id="value.systemId">
          <v-tooltip bottom v-if="item.id.detail.split_order">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span>{{ value }}</span>
                <v-icon>mdi-file</v-icon>
              </div>
            </template>
            <span>{{ item.id.detail.split_order }}</span>
          </v-tooltip>
          <div v-else>{{ value }}</div>
        </span>
      </template>
      <template #item.action="{ item }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div v-if="permissionChecker('reopen')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.reopen"
                :class="
                  !item.id.detail.actions.reopen ? 'text-muted' : 'text-dark'
                "
                @click="actionReopen(item.id.id)"
                text
              >
                Remove POD
              </v-btn>
            </div>
            <!--            <div v-if="permissionChecker('add_call')">-->
            <!--              <v-btn-->
            <!--                class="w-100 text-left"-->
            <!--                :disabled="!item.id.detail.actions.add_call"-->
            <!--                :class="-->
            <!--                  !item.id.detail.actions.add_call ? 'text-muted' : 'text-dark'-->
            <!--                "-->
            <!--                @click="actionCall(item)"-->
            <!--                text-->
            <!--                >Add Call</v-btn-->
            <!--              >-->
            <!--            </div>-->
            <div v-if="permissionChecker('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="item.id.detail.actions.update"
                :class="
                  item.id.detail.actions.update ? 'text-muted' : 'text-dark'
                "
                @click="editItem(item.id.id)"
                text
                >Edit</v-btn
              >
            </div>
            <div v-if="permissionChecker('view')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.show"
                :class="
                  !item.id.detail.actions.show ? 'text-muted' : 'text-dark'
                "
                @click="showItemDetail(item)"
                text
                >View</v-btn
              >
            </div>
          </v-card>
        </v-menu>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <EditItem
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="editModal"
      :showNextOrder="showNextOrder"
      :showPrevOrder="showPrevOrder"
      :extraData="extraData"
      :itemForAction="editItemIdData"
    ></EditItem>
    <!--    <OrderCall-->
    <!--      ref="orderCall"-->
    <!--      :call_statuses="call_statuses"-->
    <!--      :page-loader="pageLoad"-->
    <!--    ></OrderCall>-->
    <v-dialog v-model="dialog2" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>Select Hold On Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="holdOn_reason" column>
            <v-radio
              v-for="item in extraData.hold_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>

          <v-menu
            v-if="holdOn_reason == 1030"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="holdOn_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="holdOn_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionOnHold">
            On Hold
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>Cancel Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="cancel_reason" column>
            <v-radio
              v-for="item in extraData.cancel_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog3 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionCancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/pendingConfirmationOrders.module.js";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import EditItem from "@/own/components/orderManagement/pendingConfirmationOrders/EditItem.vue";
import Others from "@/own/components/orderManagement/pendingConfirmationOrders/Others.vue";

import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";

import Pagination from "@/own/components/pagination/Pagination.vue";
import Swal from "sweetalert2";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ApiService from "@/core/services/api.service";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  components: {
    DatatableDetail,
    FilterSidebar,
    ExportSidebar,
    Pagination,
    Others,
    ComponentSelector,
    EditItem,
  },
  props: {
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
    offset: true,
    columnsModal: false,

    editItemId: null,
    dialog2: false,
    dialog3: false,
    cancel_reason: null,
    holdOn_reason: null,
    holdOn_date: null,
    menu2: false,
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {}).then(() => {});
    this.$store.dispatch(UPDATE_TABLE_DATA, {}).then(() => {});
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
        .per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getPENDINGCONFIRMATIONORDERSTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getPENDINGCONFIRMATIONORDERSTableData
        .last_page;
    },
    //filters
    filters: function () {
      let filters = null;
      try {
        filters = this.$store.getters.getPENDINGCONFIRMATIONORDERSTableFilters;
      } catch {
        filters = [];
      }
      return filters;
    },
    actions: function () {
      return [
        {
          title: "View",
          method: "showItemDetail",
          isVisible: this.permissionChecker("update"),
        },
        {
          title: "Edit",
          method: "editItem",
          isVisible: this.permissionChecker("update"),
        },
        {
          title: "Remove",
          method: "removeItem",
          isVisible: this.permissionChecker("destroy"),
        },
      ];
    },
    table_permissions: function () {
      if (this.$store.getters.getPENDINGCONFIRMATIONORDERSTablePermissions) {
        return this.$store.getters.getPENDINGCONFIRMATIONORDERSTablePermissions.map(
          function (perm) {
            return perm.name;
          }
        );
      } else {
        return false;
      }
    },
    table_properties: function () {
      return this.$store.getters.getPENDINGCONFIRMATIONORDERSTableProperties;
    },
    itemPerPage: function () {
      return this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
        .per_page;
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
          .sortBy;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
          );
        } else {
          this.$store.commit(SET_TABLE_SORTBY, "");
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
          );
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (
          this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
            .sortOrder === "asc"
        ) {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(
          UPDATE_TABLE_DATA,
          this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
        );
      },
    },
    headers: function () {
      /**
       * @type {[
          {
              value: string,
              text: string,
              type: string,
              sortable: string,
              exportable: string,
              visible: string,
              mobile_visible: string,
              align: string,
              itemClass: string,
              width: string,
              class: string,
              export_order: integer,
            }
                ]}
        */
      let headers = false;
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.$store.getters.getPENDINGCONFIRMATIONORDERSTableHeaders) {
          headers =
            this.$store.getters.getPENDINGCONFIRMATIONORDERSTableHeaders.filter(
              (col) => col.visible === true && col.mobile_visible === true
            );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers =
            this.$store.getters.getPENDINGCONFIRMATIONORDERSTableHeaders.filter(
              (col) => col.visible === true && col.mobile_visible === true
            );
        }
      } else {
        if (this.$store.getters.getPENDINGCONFIRMATIONORDERSTableHeaders) {
          headers =
            this.$store.getters.getPENDINGCONFIRMATIONORDERSTableHeaders.filter(
              (col) => col.visible === true
            );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers =
            this.$store.getters.getPENDINGCONFIRMATIONORDERSTableHeaders.filter(
              (col) => col.visible === true
            );
        }
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "aging_day")
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    exportHeaders: function () {
      let headers = null;
      try {
        headers =
          this.$store.getters.getPENDINGCONFIRMATIONORDERSTablePermissions.filter(
            (col) => col.name === "export"
          )[0].columns;
      } catch {
        headers = [];
      }
      return headers;
    },
    types: function () {
      return [
        { name: "shipping label", value: "label" },
        { name: "barcode", value: "barcode" },
        { name: "invoice", value: "invoice" },
        { name: "manifest", value: "manifest" },
        { name: "packing summary", value: "packing_summary" },
        { name: "packing slip", value: "packing_slip" },
      ];
    },
    items: function () {
      let items = null;
      if (this.$store.getters.getPENDINGCONFIRMATIONORDERSTableData) {
        items = this.$store.getters.getPENDINGCONFIRMATIONORDERSTableData.data;
      }
      return items;
    },
    extraData() {
      return this.$store.getters.getPENDINGCONFIRMATIONORDERSExtraData;
    },
    editItemIdData() {
      return this.editItemId;
    },
    // call_statuses() {
    //   return this.$store.getters.getPENDINGCONFIRMATIONORDERSCallStatuses;
    // },
  },
  methods: {
    revertAudit() {
      // TODO: revert audit order function
      if (this.selected.length > 0) {
        this.pageLoad(true);
        const data = {
          order_ids: this.selected.map((item) => item.id.id),
        };
        ApiService.post(
          `/order_management/pending_confirmation_orders/mark_order_for_audit`,
          data
        )
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Revert Audit registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: `Please, select item!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    showCancelModal() {
      if (!this.dialog3) {
        this.cancel_reason = null;
        if (this.selected.length > 0) {
          this.dialog3 = !this.dialog3;
        } else {
          Swal.fire({
            title: "Warning",
            text: `Please, select item!`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        this.dialog3 = !this.dialog3;
      }
    },
    actionCancel() {
      if (this.cancel_reason) {
        this.pageLoad(true);
        this.dialog3 = false;
        const data = {
          order_ids: this.selected.map((item) => item.id.id),
          reason: this.cancel_reason,
        };
        ApiService.post(
          `/order_management/pending_confirmation_orders/cancel`,
          data
        )
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Cancel reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      }
    },
    showHoldOnModal() {
      if (!this.dialog2) {
        this.holdOn_reason = null;
        this.holdOn_date = null;
        if (this.selected.length > 0) {
          this.dialog2 = !this.dialog2;
        } else {
          Swal.fire({
            title: "Warning",
            text: `Please, select item!`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        this.dialog2 = !this.dialog2;
      }
    },
    actionOnHold() {
      if (this.holdOn_reason) {
        this.pageLoad(true);
        this.dialog2 = false;

        const data = {
          order_ids: this.selected.map((item) => item.id.id),
          reason: this.holdOn_reason,
          scheduled_date: this.holdOn_date,
        };
        ApiService.post(
          `/order_management/pending_confirmation_orders/hold`,
          data
        )
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `On Hold reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      }
    },
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getItemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getItemPerPage * this.getpageNumber - this.getItemPerPage;
          innerItems = this.items.slice(startFrom, this.getItemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    async findNextOrderId(id) {
      // TODO: find and return next order id
      let nextIdIndex = -1;
      let nextId = null;
      this.items.forEach((element, index) => {
        if (element.id.id === id) {
          nextIdIndex = index + 1;
        }
      });
      // console.log("index is", nextIdIndex);
      if (parseInt(this.getItemPerPage) == nextIdIndex) {
        // console.log("last item");
        if (this.pageCount > this.getPageNumber) {
          // console.log("next page exist");
          this.setPageNumber(this.getPageNumber + 1);
          await this.$store
            .dispatch(
              UPDATE_TABLE_DATA,
              this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
            )
            .then(() => {
              this.$forceUpdate();
              // console.log("on update", this.items[0].id.id);
            });
          // console.log("next item id is", this.items[0].id.id);
          nextId = this.items[0].id.id;
        }
      } else {
        nextId = this.items[nextIdIndex].id.id;
      }
      // console.log("next id is", this.items[nextIdIndex].id.id, nextId);
      return nextId;
    },
    async findPrevOrderId(id) {
      // TODO: find and return next order id
      let nextIdIndex = -2;
      let nextId = null;
      this.items.forEach((element, index) => {
        if (element.id.id === id) {
          nextIdIndex = index - 1;
        }
      });
      // console.log("index is", nextIdIndex);
      if (nextIdIndex < 0) {
        // console.log("first item");
        if (this.getPageNumber > 1) {
          // console.log("prev page exist");
          this.setPageNumber(this.getPageNumber - 1);
          await this.$store
            .dispatch(
              UPDATE_TABLE_DATA,
              this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
            )
            .then(() => {
              this.$forceUpdate();
              // console.log("on update", this.items[0].id.id);
            });
          nextId = this.items[parseInt(this.getItemPerPage) - 1].id.id;
        }
      } else {
        nextId = this.items[nextIdIndex].id.id;
      }
      // console.log(nextIdIndex, nextId, this.getPageNumber, this.getItemPerPage);
      return nextId;
    },
    showNextOrder(id) {
      // console.log("current id", id);
      this.$refs.editModal.toggleModal();
      this.findNextOrderId(id).then((data) => {
        // console.log("find next order ", data);
        if (data) {
          this.editItem(data);
          this.updateTableContent();
        }
      });
    },
    showPrevOrder(id) {
      this.$refs.editModal.toggleModal();
      this.findPrevOrderId(id).then((data) => {
        if (data) {
          this.editItem(data);
          this.updateTableContent();
        }
      });
    },
    editItem(id) {
      this.editItemId = id;
      this.$nextTick(() => {
        this.$refs.editModal.toggleModal();
      });
    },
    actionReopen(id) {
      this.pageLoad(true);
      let data = { id: id };
      ApiService.post("/fulfillment/orders/reopen", data)
        .then(() => {
          Swal.fire("Modified!", "Order modified!", "success");
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    // actionCall(item) {
    //   this.$refs.orderCall.$props.item = item;
    //   this.$refs.orderCall.toggleModal();
    // },
    // pagination
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      // console.log("setting value to filter -->", val);
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState,
      };
      // console.log("state", state);
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      // this.$refs.filterSidebar.$refs.filter.clearFiltersState();
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
        )
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    // filters end
    /**
     * checks permission and return true/false
     * @types {boolean}
     * example "view","create","update","destroy","show","generate","status","print","activate","upload"
     **/
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    loadDetailData(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { order_id: id };
      ApiService.post("/fulfillment/orders/show", data)
        .then((response) => {
          // this.$refs.otherData.$props.isLoaded = true;
          // this.$refs.otherData.$props.other = response.data.detail;
          this.$refs.otherData.setData(response.data.detail);
          this.$refs.otherData.toggleModal();
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    showItemDetail(item) {
      this.loadDetailData(item.id.id);
    },
    // eslint-disable-next-line no-unused-vars
    showDetail(val, { item, isMobile = true }) {
      // console.log(isMobile);
      // console.log("idd", item.id.id);

      this.loadDetailData(item.id.id);
      // this.editItem(item.id.id);
    },
    isAnyFilterSelected() {
      let isSelected = false;
      if (
        this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState.filters
          .length > 0
      ) {
        isSelected = true;
      }
      return isSelected;
    },
    // exports
    downloadItem(columns) {
      if (this.isAnyFilterSelected()) {
        this.$store.commit(SET_PAGE_LOADING, true);
        // console.log("order table state", this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState);
        this.$store
          .dispatch(EXPORT_TABLE_DATA, {
            columns: columns,
            filters:
              this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
                .filters,
          })
          .then(() => {
            const link = document.createElement("a");
            link.href =
              this.$store.getters.getPENDINGCONFIRMATIONORDERSExportUrl;
            link.download = "Export.csv";
            link.click();
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.exportSidebar.$refs.export.toggleModal();
            link.remove();
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    // exports end
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          ApiService.post(`aaa/${item.id}/destroy`)
            .then(() => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    toggleColumnsModal() {
      this.columnsModal = !this.columnsModal;
    },

    showUploadModal() {
      // this.$refs.upload.toggleUploadModal();
      this.$router.push({
        name: "settings.uploads",
        query: {
          type: this.$store.getters.getPENDINGCONFIRMATIONORDERSUploadType,
        },
      });
    },
    // showExportModal() {
    //   if (this.isAnyFilterSelected()) {
    //     this.$refs.export.toggleModal();
    //   } else {
    //     Swal.fire({
    //       title: "Warning",
    //       text: "Please, select at least one filter!",
    //       icon: "warning",
    //       showConfirmButton: false,
    //       timer: 2500,
    //     });
    //   }
    // },
    async updateTableContent() {
      this.pageLoad(true);
      await this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.$store.getters.getPENDINGCONFIRMATIONORDERSTableState
        )
        .then(() => {
          // console.log("on update", this.items);
        });

      this.selected = [];
      this.$forceUpdate();
      this.pageLoad(false);
    },
  },
};
</script>
